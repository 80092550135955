'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

# スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,{
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
  }
)

$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  # フッター高さ取得
  addFooterHeight =
    ()->
      add = 0
      height = $('#footer').height()
      outerHeight = $('#footer').outerHeight(true)
      diff = height - outerHeight
      if diff > 0
        $('#content').css('padding-bottom',height+add)
        $('#footer').css('height',height)
      else
        $('#content').css('padding-bottom',outerHeight+add)
        $('#footer').css('height',height)
      return

  addFooterHeight()

  $(window).on('load resize',
    ->
      addFooterHeight()
  )

  scrollToAnchor =
    (target, duration)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate({
        scrollTop:$(target).offset().top - $('#gnavi').height() - 20
      },{
        easing:'easeInOutCirc',
        duration: duration
      })

  $('a[href^="#"]'+'a[href!="#"]').on('click.smoothScroll'
    (e)->
      e.preventDefault()

      scrollToAnchor($(this).attr('href'), 1000)
      return
  )

  if location.href.indexOf('#') isnt -1
    scrollToAnchor('#'+location.href.split('#')[1], 0)

    $(window).on('load',
      ()->
        scrollToAnchor('#'+location.href.split('#')[1], 0)
    )

  $(window).scroll(
    ()->
      percentage = $(this).scrollTop() / ($(document).height() - $(this).height()) * 100

      # クイックメール エラー時
      if percentage > 30
        $('body').addClass('is-fix')
      else
        $('body').removeClass('is-fix')

      # トップへ戻るボタン
      if percentage > 30
        $('.r-pagetop').addClass('is-active')
      else
        $('.r-pagetop').removeClass('is-active')
  )

  quickMail =
    ($insertTarget, path, conf)->
      $target = $insertTarget

      $target.load(path,
        (data)->
          $form = $target.find('form')
          conf = conf

          conf.customAddError =
            ()->
              return
          conf.customScrollAdjust =
            ()->
              return $(window).scrollTop() + 10
          conf.customClearError =
            ()->
              $.ajax({
                url:$form.attr('action')
                type:$form.attr('method')
                data:$form.serialize()
                dataType:'html'
                timeout:10000
                beforeSend:
                  (xhr,settings)->
                    return
                complete:
                  (xhr,status)->
                    return
                success:
                  (result,status,xhr)->
                    inputObj = result
                    $target.empty()
                    $target.append(result)

                    $target.find('a').on('click',
                      (e)->
                        e.preventDefault()
                        $target.empty()
                        $target.load(path,
                          ()->
                            $('.formError').each(
                              ()->
                                if $(this).attr('class').indexOf($form.attr('id')) isnt -1
                                  $(this).remove()
                            )

                            quickMail($target, path, conf)
                        )
                    )
              })
              return

          $form.exValidation(conf)

          $(window).on('scroll resize',
            ()->
              $('.formError').each(
                ()->
                  position = $('#'+$(this).attr('id').replace('err_', '')).offset()
                  if $(this).attr('id').indexOf('s-') isnt -1
                    if $('.s-fix-contents').hasClass('is-fixed')
                      position = $('.is-fixed #'+$(this).attr('id').replace('err_', '')).offset()
                      $(this).css({
                        'position': 'fixed'
                        'top': position.top - $(window).scrollTop() - $(this).outerHeight()
                        'left':  position.left + 176
                      })
                    else
                      $(this).css({
                        'position': 'absolute'
                        'top': position.top - $(this).outerHeight()
                        'left':  position.left + 176
                      })
              )
          )
      )

  # 固定コンテンツ制御
  fixedContents =
    ()->
      mainHeight = $('#main').height()
      subHeight = $('#sub').outerHeight(true)
      footerHeight = $('#footer').outerHeight()

      if subHeight < mainHeight
        $('#sub').css('min-height', $('.m-contents-wrapper').outerHeight(true))

        mainContentsHeight = $('.m-contents-wrapper').outerHeight(true)
        subContentsHeight = $('.s-contents-wrapper').outerHeight(true)
        windowHeight = $(window).height()
        # 30 = メニュー下マージン
        adjustMargin = $('.gnavi').outerHeight() + 30

        $fixContents = $('.s-fix-contents')
        fixContentsHeight = $('.s-fix-contents').outerHeight(true)
        fixContentsPos = $fixContents.offset().top - adjustMargin

        fixFlg = false
        bottomFlg = false

        $(window).scroll(
          ()->
            # メインコンテンツの高さが変わった時時再度高さ適応
            currentSubHeight = $('#sub').outerHeight(true)
            currentMainContentsHeight = $('.m-contents-wrapper').outerHeight(true)

            if currentMainContentsHeight > mainContentsHeight
              $('#sub').css('min-height', currentMainContentsHeight)

            currentSubContentsHeight = $('.s-contents-wrapper').outerHeight(true)

            # メニュー開閉等で位置が変わった時再計算
            currentFixContentsPos = $('.s-fix-contents').offset().top - adjustMargin

            if $('.s-fix-contents').not('.is-fixed,.is-bottom').length isnt 0
              #currentFixContentsPos = $('.s-fix-contents').not('.is-fixed,.is-bottom').offset().top - adjustMargin

              if currentFixContentsPos > fixContentsPos
                fixContentsPos = currentFixContentsPos

            # サブコンテンツの方が高さが低い時のみ実行
            if currentSubContentsHeight < currentMainContentsHeight
              scrollPos = $(window).scrollTop()
              fixContentsBottomPos = $('#container').height() - (scrollPos + windowHeight) - $('#footer').outerHeight() - ((adjustMargin + fixContentsHeight) - windowHeight)

              if scrollPos > fixContentsPos
                if not fixFlg and not bottomFlg
                  $fixContents.addClass('is-fixed')
                  fixFlg = true
              else
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

              if fixContentsBottomPos < 0
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

                  if not bottomFlg
                    $fixContents.addClass('is-bottom')
                    bottomFlg = true
              else
                if bottomFlg
                  $fixContents.removeClass('is-bottom').addClass('is-fixed')
                  bottomFlg = false
        )
      else
        $('.m-contents-wrapper-inner').css('min-height', $('#sub').outerHeight())

  if $('.h-tel').get(0)?
    $('.h-tel-tab-item').on('mouseenter',
      (e)->
        $('.h-tel-tab-item').removeClass('is-active')
        $(this).addClass('is-active')

        $('.h-tel-item').removeClass('is-active')
        $($(this).find('a').attr('href')).addClass('is-active')
    )

    $('.h-tel-tab-item > a').on('focus mouseover',
      (e)->
        $(this).off('click.smoothScroll')
    )

    $('.h-tel-tab-item > a').on('click',
      (e)->
        e.preventDefault()
    )

  if $('#sub').get(0)?
    # タブ
    $('.c-tab-item > a').on('focus mouseover',
      (e)->
        $(this).off('click.smoothScroll')
    )

    $('.c-tab-item > a').on('click',
      (e)->
        e.preventDefault()

        $target = $($(this).attr('href'))
        $(this).parents('.c-tab').find('.c-tab-item').removeClass('is-active')
        $(this).parent().addClass('is-active')
        $(this).parents('.c-tab').siblings('.c-tab-contents').find('.c-tab-contents-item').removeClass('is-active')
        $target.addClass('is-active')

        if $(this).attr('href').indexOf('mail') is -1
          $('.formError[class*=s-]').each(
            ()->
              $(this).hide()
          )
        else
          if $('.s-concierge-mail').find('form').length isnt 0 and $('.formError[class*=s-]').css('top') isnt 'auto'
            $('#s-concierge-mail-form').find('.err').each(
              ()->
                $('#err_'+$(this).attr('id')).show()
            )
    )

    $(window).on('load',
      ()->
        fixedContents()
    )

    # クイックメール 設定
    $formWrapper = $('#s-concierge-mail').find('.form-wrapper')

    conf = {
      rules:{
        's-concierge-name': 'chkrequired'
        's-concierge-email': 'chkrequired chkemail'
        's-concierge-text': 'chkrequired'
      },
      errFocus:true,
      errTipPos:'right',
      errMsgPrefix:'※',
      errZIndex:1100,
    }

    quickMail($formWrapper, url + 'form/sub/', conf)

  if $('#gnavi').get(0)?
    # ヘッダー追従 設定
    firstDownFlg = true
    firstUpFlg = false

    $('.gnavi').headroom({
      offset: $('#header').outerHeight() - $('.gnavi').outerHeight()
      classes: {
        initial: 'animated'
        pinned: 'slideDown'
        unpinned: 'slideUp'
      }
      onPin: ()->
        firstUpFlg = true
        if not firstDownFlg
          $('.gnavi').removeClass('slideUpFirst')
      onUnpin: ()->
      onTop: ()->
        firstDownFlg = true
        $('.gnavi').removeClass('slideDownFirst')
      onNotTop: ()->
        if firstDownFlg
          $('.gnavi').addClass('slideUpFirst')
          firstDownFlg = false
    })

    $(window).scroll(()->
      if firstUpFlg and $(this).scrollTop() <= $('#header').height() + $('.gnavi').height()
        $('.gnavi').addClass('slideDownFirst')
        firstUpFlg = false
    )

    subMenuHeights = {}

    $('.gnavi-item').each(
      ()->
        if $(this).hasClass('has-child')
          $target = $(this).find('.gnavi-child-list')
          subMenuHeights[$(this).attr('class').split(' ')[0]] = $target.height()
          $target.addClass('is-active')
    )

    # ヘッダーサブメニュー
    $('#gnavi').find('.gnavi-item > a').on('mouseenter mouseleave',
      ()->
        $target = $('#gnavi').find('.gnavi-child-list')

        $target.removeAttr('style').addClass('is-active')
    )

    $('#gnavi').find('.has-child > a').on('mouseenter',
      ()->
        $childList = $(this).siblings('.gnavi-child-list')

        targetClass = $(this).parent().attr('class')
        target = targetClass.split(' ')[0]

        $.each(subMenuHeights,
          (key, val)->
            if key is target
              $childList.css('max-height', val)
              return false
        )

        $childList.on('mouseenter',
          ()->
            $.each(subMenuHeights,
              (key, val)->
                if key is target
                  $childList.css('max-height', val)
                  return false
            )
        )
    )

    $('#gnavi').find('.gnavi-child-list').on('mouseleave',
      ()->
        $(this).removeAttr('style').addClass('is-active')
    )

  $('.m-column-list').slick({
    infinite: true
    variableWidth: true
  })
  
)
